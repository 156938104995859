import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 psp 프로젝트를 가져옵니다.
 * @param {String} params
 */
export async function fetchAllPspProjects() {
  const queryParams = [];
  queryParams.push('order=-createdAt');
  const res = await axios.get(
    `/v1/admin/psp/projects?${queryParams.join('&')}`,
  );
  console.log('res', res);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 특정 psp 프로젝트를 가져옵니다.
 */
export async function fetchPspProject(id) {
  const res = await axios.get(`/v1/admin/psp/projects/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * psp 프로젝트를 생성합니다.
 * @param {Object} data
 */
export async function createPspProject(data) {
  const res = await axios.post('/v1/admin/psp/projects', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * psp 프로젝트를 수정합니다.
 * @param {string} id
 * @param {Object} data
 */
export async function updatePspProject(id, data) {
  const res = await axios.patch(`/v1/admin/psp/projects/${id}`, data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * psp 프로젝트를 삭제합니다.
 * @param {string} id
 */
export async function deletePspProject(id) {
  const res = await axios.delete(`/v1/admin/psp/projects/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 가져옵니다.
 * @param {string} id
 */
export async function fetchPspDocument(id) {
  const res = await axios.get(`/v1/admin/psp/photos/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 생성합니다.
 * @param {Object} data
 */
export async function createPspDocument(data) {
  const res = await axios.post('/v1/admin/psp/photos', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 수정합니다.
 * @param {string} id
 * @param {Object} data
 */
export async function updatePspDocument(id, data) {
  const res = await axios.patch(`/v1/admin/psp/photos/${id}`, data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 삭제합니다.
 * @param {string} id
 */
export async function deletePspDocument(id) {
  const res = await axios.delete(`/v1/admin/psp/photos/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 가져옵니다.
 * @param {string} id
 */
export async function fetchPspTerm(termId, versionId) {
  const res = await axios.get(`/v1/admin/psp/terms/${termId}/${versionId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 생성합니다.
 * @param {Object} data
 */
export async function createPspTerm(data) {
  const res = await axios.post('/v1/admin/psp/terms', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 수정합니다.
 * @param {string} id
 * @param {Object} data
 */
export async function updatePspTerm(id, data) {
  const res = await axios.patch(`/v1/admin/psp/terms/${id}`, data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 삭제합니다.
 * @param {string} id
 */
export async function deletePspTerm(id) {
  const res = await axios.delete(`/v1/admin/psp/terms/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목의 특정 버전을 삭제합니다.
 * @param {string} id
 * @param {string} versionId
 */
export async function deletePspTermVersion(id, versionId) {
  const res = await axios.delete(`/v1/admin/psp/terms/${id}/${versionId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관에 대한 재동의를 요청하는 푸시 알림을 발송합니다.
 * @param {string} id - psp project ID
 * @param {boolean} isNok - 대리인 푸시 발송 여부
 */
export async function requestPspTermReAgreement(id, isNok = false) {
  const res = await axios.post(
    `/v1/admin/psp/projects/${id}/notifications${
      isNok ? '?isRefundNOK=true' : ''
    }`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * psp 프로젝트에 대한 신청 리스트를 가져옵니다.
 * @param {string} id
 */
export async function fetchPspProjectRequests(id) {
  const res = await axios.get(`/v1/admin/psp/projects/${id}/requests`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 유저의 psp 신청내역을 가져옵니다.
 * @param {string} userId
 */
export async function fetchPspUserRequest(userId) {
  const res = await axios.get(`/v1/admin/psp/projects/users/${userId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 유저의 psp 신청내역을 수정합니다.
 * @param {string} requestId
 * @param {Object} data
 * @param {boolean} sendPush
 */
export async function updatePspUserRequest(requestId, data, sendPush) {
  const res = await axios.patch(
    `/v1/admin/psp/requests/${requestId}${sendPush ? '?isSendPush=true' : ''}`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 유저의 psp 신청의 변경내역을 가져옵니다.
 * @param {string} requestId
 */
export async function fetchPspUserRequestHistories(requestId) {
  const res = await axios.get(`/v1/admin/psp/requests/${requestId}/histories`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * psp에 제출된 문서를 다운로드합니다.
 * @param {string} requestId
 * @param {string} documentId
 */
export async function downloadPspDocument(requestId, documentId) {
  const res = await axios.get(
    `/v1/admin/psp/requests/${requestId}/documents/${documentId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * psp에 제출된 문서 전체를 다운로드합니다.
 * @param {string} requestId
 */
export async function downloadPspDocuments(requestId) {
  const res = await axios.get(`/v1/admin/psp/requests/${requestId}/documents`, {
    responseType: 'blob',
  });
  return res.data;
}

/**
 * psp 프로젝트에 대한 대량 이체 엑셀을 다운로드합니다.
 * @param {string} id
 */
export async function downloadPspProjectFinanceExcel(id) {
  const res = await axios.get(`/v1/admin/psp/projects/${id}/finance`);
  return res.data;
}

/**
 * psp 프로젝트에 대한 제약사 보고 엑셀을 다운로드합니다.
 * @param {string} id
 */
export async function downloadPspProjectReportExcel(id) {
  const res = await axios.get(`/v1/admin/psp/projects/${id}/report`);
  return res.data;
}
